var getClassName = function(channelName){
    var productId = localStorage.getItem("currentProduct");
    if(productId == "1" || productId == "4" || productId =="12"){
        if(channelName === "sponsorship value"){
            return "sponsorship";
        }else if(channelName === "In Venue"){
            return "invenue";
        }else{
            return channelName;
        }
    }
    else{
        return "wassaman";
    }
}

export  {getClassName};