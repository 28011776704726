import React from 'react'
import ReactEcharts from 'echarts-for-react';
import { Label } from 'semantic-ui-react'
import _ from 'lodash'
import { BlueMoon, RequestToken } from '../BlueMoon'
import axios from 'axios'
import { isThisISOWeek } from 'date-fns';
import {numericConvertor} from '../Common/NumericConvertor'

class PieChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: this.props.params,
            doughNutData: [],
            pieChartData: [],
            channelToColor: {
                "Social": '#4444A5',
                "Digital": "yellow",
                "Highlights": '#FF9027',
                "Linear": '#70dbff',
            } 
        }
    }

    fetchPieChart = () => {
        axios.get(`${BlueMoon.ip}dashboard/socialContrib/`, {
            params: this.state.params
        }).then((response) => {
            this.setState({ pieChartData: response.data.socialContrib });
        }).catch((err)=>{
            RequestToken();
        });
    }

    componentWillReceiveProps = (nextProps, currentProps) => {
        if (!_.isEqual(nextProps.params, this.state.params)) {
            this.setState({ doughNutData: [], pieChartData: [], params: nextProps.params }, () => {
                this.fetchData();
            })
            return true;
        }
        return true;
    }

    fetchDoughNut = () => {
        axios.get(`${BlueMoon.ip}dashboard/channelContrib/`, {
            params: this.state.params
        }).then((response) => {
            this.setState({ doughNutData: response.data.channelContrib });
        }).catch((err)=>{
            RequestToken();
        });;
    }

    fetchData = () => {
        this.fetchPieChart();
        this.fetchDoughNut();
    }

    componentDidMount = () => {
        this.fetchData();
    }


    generatePieChart = (doughNutData, pieChartData) => {
        // console.log(doughNutData, pieChartData)
        var doughNutData = (doughNutData).map((data) => {
            data['itemStyle'] = { 'color': this.state.channelToColor[data.name]};
            return data
        }, {});


        pieChartData = pieChartData.map((val) => {
            return {
                name: _.capitalize(val.name), value: val.value
            }
        })
        
        const option = {
            backgroundColor: "#161A1C",
            tooltip: {
                trigger: 'item',
                formatter: function (params, ticket, callback) {
                    // console.log(params)
                    return `${params.data.name} (${numericConvertor(params.percent)}%)`;
                }
            },
            title: {
                text: 'Channel Share Of Value',
                textStyle: {
                    fontFamily: 'sans-serif',
                }, 
            },
           
            series: [
                {
                    name: 'social',
                    type: 'pie',
                    selectedMode: 'single',
                    radius: [0, '35%'],
                    label: {
                        show: false
                    },
                   
                    data: pieChartData
                },
                {
                    name: 'channel',
                    type: 'pie',
                    radius: ['50%', '72%'],
                    label: {
                        show: false
                    },
                    data: doughNutData,

                }
            ]
        }

        return option;
    }




    render() {

        if (!(_.isEmpty(this.state.pieChartData) && _.isEmpty(this.state.doughNutData)))
            return (
                <div style={{width: '100%', backgroundColor: 'black', borderRadius: '25px' }}>
                    {/* <Label as='a' ribbon color={'blue'} style={{ marginLeft: '1rem'}} size={'large'}>{"Channel share of value"}</Label> */}
                    <ReactEcharts
                        style={{boxShadow: "hsl(200 12% 30%) 0 0 8px"}}
                        option={this.generatePieChart(this.state.doughNutData, this.state.pieChartData)}
                        notMerge={true}
                        lazyUpdate={true}
                        theme={"dark"} />
                </div>
            )
        return null;

    }
}

export default PieChart;