import React from "react";
import { Grid, Button, Icon } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash'
import Picky from 'react-picky';
import 'react-picky/dist/picky.css';
import AWSDatePicker from '../DatePicker/AWSDatePicker'
import { BlueMoon, RequestToken } from '../BlueMoon'
import axios from 'axios'
import { shouldTypeVisible } from '../Common/CommanFunc'

class FilterDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            brands: [],
            filter: ['month', 'week', 'day'],
            channel: [
                'Social', 'Highlights', 'Linear', 'Digital'


            ],
            updatedPlaceHolder: (_.isEmpty(this.props.displayFilter)) ? {
                duration: "duration", Channel: "channel", "Channel Type": "type", Brand: "brand", startDate: new Date(), selectedDays: [], asset: "asset", eventDates: []

            } : this.props.displayFilter,
            params: this.props.params,
            comp: false,

        }
    }

    updatedPlaceHolder = (displayFilter) => {
        this.setState({
            updatedPlaceHolder: (_.isEmpty(displayFilter)) ? {
                duration: "duration", Channel: "channel", "Channel Type": "type", Brand: "brand", startDate: new Date(), selectedDays: [], asset: "asset", eventDates: []

            } : displayFilter
        })
    }


    getUniqueBrands = () => {
        axios.get(`${BlueMoon.ip}distinct/brands/`, {
        }).then((response) => {
            if (response.data.length > 0) {
                let data = response.data
                data.push("All-brands")
                this.setState({ brands: data });
            }
        }).catch((err) => {
            RequestToken();
        });
    }

    getUniqueAssets = () => {
        axios.get(`${BlueMoon.ip}assetNames/`, {
            params: _.isEmpty(this.state.params.Brand) ? "" : {
                brand: this.state.params.Brand
            }
        }).then((response) => {
            let data = response.data
            // console.log(response.data)
            data.push('All-assets')
            this.setState({ asset: data });
        }).catch((err) => {
            RequestToken();
        });
    }



    componentDidMount = () => {
        this.getUniqueBrands();
        this.getUniqueAssets();
    }


    componentDidUpdate = () => {
        var dropDowns = document.getElementsByClassName('ui fluid floating search selection dropdown icon');
        if (!_.isUndefined(dropDowns[0])) {
            for (var i = 0; i < dropDowns.length; i++) {
                dropDowns[i].style.backgroundColor = 'violet'
                if (!_.isUndefined(dropDowns[i].getElementsByClassName('default text')[0])) {
                    dropDowns[i].getElementsByClassName('default text')[0].style.color = 'black';
                    dropDowns[i].getElementsByClassName('default text')[0].style.fontFamily = 'sans-serif';
                    dropDowns[i].getElementsByClassName('default text')[0].style.fontWeight = '900';
                }
            }

        }

    }



    onSelectFilter = (filterName, value) => {
        console.log(value, filterName);
        // console.log(value.slice(-2)[0])
        var lastValue = value.slice(-1)[0];
        if (filterName != "duration")
            value = value.filter((val) => !_.isEmpty(val));

        let temp = this.state.updatedPlaceHolder;
        temp[filterName] = value.value;
        this.setState({ updatedPlaceHolder: temp }, () => {
            if (filterName === "duration")
                if (value == "month")
                    this.props.selectFilter({ [filterName]: "monthname" }, this.state.updatedPlaceHolder);
                else if(value === "day")
                    this.props.selectFilter({ [filterName]: "all" }, this.state.updatedPlaceHolder);
                else
                    this.props.selectFilter({ [filterName]: value }, this.state.updatedPlaceHolder);
            else if (_.isEmpty(value))
                this.props.selectFilter(this.mapDashToServName(filterName), this.state.updatedPlaceHolder, true)
            else if (_.isEqual(lastValue, "All-brands") || _.isEqual(lastValue, "All-assets"))
                this.props.selectFilter({ [this.mapDashToServName(filterName)]: "all" }, this.state.updatedPlaceHolder);
            else if (_.isEqual(value.slice(-2)[0], "All-brands") || _.isEqual(value.slice(-2)[0], "All-assets")) {
                value = _.slice(value, 1, value.length);
                // console.log(value)
                this.props.selectFilter({ [this.mapDashToServName(filterName)]: _.join(value, "<<!!>>") }, this.state.updatedPlaceHolder);
            }
            else
                this.props.selectFilter({ [this.mapDashToServName(filterName)]: _.join(value, "<<!!>>") }, this.state.updatedPlaceHolder);

        });

    }


    componentWillReceiveProps = (nextProps, currentProps) => {

        if (!_.isEqual(this.state.params, nextProps.params)) {
            this.setState({ params: nextProps.params }, () => {
                this.getUniqueAssets();
                this.updatedPlaceHolder(nextProps.displayFilter);
            });
            return true;
        }
        return false;
    }

    mapDashToServName = (name) => {
        switch (name) {
            case "Channel Type": return "channel_type";
            case "asset": return "location";
            default: return name;

        }
    }



    customDropDownBox = (text, placeholderText, options) => {
        var placeHolder = this.mapDashToServName(placeholderText);
        console.log("placeHolder", placeHolder)
        var visible = true
        if (placeholderText === "Channel Type") {
            console.log(this.state.params['Channel'])
            if (!shouldTypeVisible(this.state.params['Channel']))
                visible = false
        }
        return (

            <Picky style={{ cursor: 'pointer' }}
                numberDisplayed={1}
                options={(!visible) ? [] : options}
                placeholder={_.capitalize(placeholderText)}

                value={_.isUndefined(this.state.params[placeHolder]) ? [] : _.isEqual(this.state.params[placeHolder], "monthname") ? "month" : (_.isEqual(this.state.params[placeHolder], "all") &&  _.isEqual(placeHolder, "duration"))?"day":  _.includes(this.state.params[placeHolder].split("<<!!>>"), "all") ? ["All-" + _.lowerCase(placeholderText) + "s"] : this.state.params[placeHolder].split("<<!!>>")}
                multiple={(placeholderText === "duration") ? false : true} includeFilter={true}

                onChange={values => this.onSelectFilter(placeholderText, values)} />
        )
    }



    changeStyleToDefault = () => {
        document.getElementsByTagName('input')[0].value = 'YYYY-MM-DD';
    }

    reset = () => {
        this.props.selectFilter("reset", []);
    }
    render() {
        if (_.isEmpty(this.state.brands)) return null;

        return (
            <Grid stackable style={{ backgroundColor: '#161A1C', marginLeft: '0.2rem', marginRight: '0.1rem', marginTop: '1rem' }} >
                <Grid.Row style={{ width: '80%' }}>
                    <Grid.Column width={1} textAlign={'center'} verticalAlign={'middle'}>
                        <Icon color={'teal'} onClick={
                            this.reset
                        } name={'refresh'} size={'large'} style={{ cursor: 'pointer' }} />
                    </Grid.Column>
                    <Grid.Column width={1} textAlign={'left'} verticalAlign={'top'} style={{ alignSelf: 'end !important' }}>
                        <AWSDatePicker selectFilter={this.props.selectFilter} placeholder={this.state.updatedPlaceHolder} />
                    </Grid.Column>
                    <Grid.Column width={3} textAlign={'center'} verticalAlign={'middle'}>
                        {this.customDropDownBox(this.state.updatedPlaceHolder.Brand, "Brand", this.state.brands)}
                    </Grid.Column>
                    <Grid.Column width={2} textAlign={'center'} verticalAlign={'middle'}>
                        {this.customDropDownBox(this.state.updatedPlaceHolder.duration, "duration", this.state.filter)}
                    </Grid.Column>
                    <Grid.Column width={2} textAlign={'center'} verticalAlign={'middle'}>
                        {this.customDropDownBox(this.state.updatedPlaceHolder.Channel, "Channel", this.state.channel)}
                    </Grid.Column>
                    <Grid.Column width={2} textAlign={'center'} verticalAlign={'middle'}>
                        {this.customDropDownBox(this.state.updatedPlaceHolder["Channel Type"], "Channel Type", ["earned", "owned"])}
                    </Grid.Column>
                    <Grid.Column width={3} textAlign={'center'} verticalAlign={'middle'}>
                        {this.customDropDownBox(this.state.updatedPlaceHolder["asset"], "asset", this.state.asset)}
                    </Grid.Column>
                    <Grid.Column>
                        <Button color={'blue'} onClick={this.props.fetchFilterData}>{'Apply'}</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>);
    }

}

export default FilterDashboard;