import React from 'react'
import { Grid, Icon, Statistic, Label } from 'semantic-ui-react'
import _ from 'lodash'
import axios from 'axios'
import { BlueMoon, RequestToken } from '../BlueMoon'
import { numberWithCommas } from '../Common/NumberWithComas'
import Helmet from 'react-helmet'
import { isMobile } from 'react-device-detect'

class ComparisonCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            channelToColor: {
                "Social": '#4444A5',
                "Digital": "yellow",
                "Highlights": '#FF9027',
                "Linear": '#44A1A5',

            }, data: this.props.data,
            channelToIcon: {
                "Linear": "video",
                "Social": "world",
                "Digital": "tv",
                "Highlights": "star"
            }, params: this.props.params

        };
    }

    fetchComparisonData = () => {
        axios.get(`${BlueMoon.ip}dashboard/comparisonGraph/`, {
            params: this.state.params
        }).then((response) => {

            this.setState({
                data: response.data.comparisonGraph.map((value) => {
                    return { channelName: value.channel, channelValue: _.round(value.current_value), comparisonData: value.previous_value }
                })
            });
        }).catch((err)=>{
            RequestToken();
        });
    }

    componentDidMount = () => {
        this.fetchComparisonData();
    }

    componentWillReceiveProps = (nextProps, currentProps) => {
        if (!_.isEqual(nextProps.params, this.state.params)) {
            this.setState({ params: nextProps.params, data: [] }, () => {
                this.fetchComparisonData();
            });
            return true;
        }
        return false;
    }

    comparisonCard = (channelName, icon, value, raise, percentValue, index) => {
        return (<Grid style={{ backgroundColor: index % 2 == 0 ? 'hsl(200 12% 10%)': 'hsl(200 12% 20%)', color: 'white' }} >
            <Grid.Row style={{ backgroundColor: this.state.channelToColor[channelName], color: 'black' }}>
                <Grid.Column width={8} style={{ fontFamily: 'sans-serif', fontWeight: '900', fontSize: '1.2rem' }} >
                    {channelName}
                </Grid.Column>
                <Grid.Column width={6} textAlign={'right'}>
                    <Icon name={icon} color={'black'} size={'big'} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>
                <Grid.Column>
                    <Statistic size={'mini'}>
                        <Statistic.Value style={{ color: 'white' }}>${numberWithCommas(value)}</Statistic.Value>
                    </Statistic>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>
                <Grid.Column style={{ fontFamily: 'sans-serif', fontWeight: '900', fontSize: '1.2rem', padding: '0', paddingLeft: '10px' }} width={16}>
                    <Icon name={(raise == "same")? "arrow right": (raise == "increase") ? 'arrow up' : 'arrow down'} color={(raise == "same")? "grey": (raise == "increase") ? 'green' : 'red'} style={{ fontSize: '1.6em' }} />
                    <Statistic>
                        <Statistic.Value style={{ color: (raise == "same")?'grey':(raise == "increase") ? 'green' : 'red', fontSize: '1em !important' }}>{percentValue}</Statistic.Value>
                    </Statistic><span style={{ color: 'white', fontSize: '0.8em', marginLeft: '0.4rem' }}> from the previous {(!_.isUndefined(this.state.params['duration'])) ? (this.state.params['duration'] == "monthname") ? "month" : this.state.params['duration'] : "day"}</span>

                </Grid.Column>

            </Grid.Row>
        </Grid>)
    }

    grid = () => {
        return (<Grid stackable style={{ marginLeft: '1rem' }}>
            <Grid.Row style={{ marginRight: '1rem' }}>
                {
                    _.map(this.state.data, (channel, cmpIndex) => {
                        // console.log(channel);
                        var currentValue = channel.channelValue;
                        var prevValue = channel.comparisonData;
                        if(currentValue == 0 && prevValue == 0){
                            percent = 0;
                            reach = "same";
                        }else{
                        var percent = (prevValue == 0) ? 1 : _.round(currentValue / prevValue, 2);
                        
                        var reach = (currentValue == 0 && prevValue > 0) ? "decrease" : (percent < 1) ? "decrease" : "increase"
                        var percent = ((percent == 1) ? 1 : (percent < 1) ? 1 - percent : percent - 1) * 100;
                        }
                        return (
                            <Grid.Column style={{ width: "25%" }} >{this.comparisonCard(channel.channelName, this.state.channelToIcon[channel.channelName], currentValue, reach, `${_.round(percent, 2)}%`, cmpIndex)}</Grid.Column>
                        )
                    })
                }


            </Grid.Row>
        </Grid>)
    }

    componentDidUpdate = () => {
        var statsVal = document.getElementsByClassName('ui statistic');

        for (var i = 6; i < statsVal.length; i++) {
            statsVal[i].getElementsByClassName('value')[0].style.fontSize = window.innerWidth / 4 / 3 / 100 + "em !important"
        }
    }

    render() {
        if (_.isEmpty(this.state.data)) return null;

        return <div style = {{marginLeft: (isMobile)? "1rem": "0rem"}} >

            <Label as='a' ribbon color={'blue'} style={ { marginLeft: `${(isMobile)?"0em":"2rem"}`, marginTop: '1rem', position: 'bottom', bottom: '1rem ' } } size={'large'}>{"Channel Comparison"}</Label>
            {this.grid()}
        </div>
    }
}

export default ComparisonCard;