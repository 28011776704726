import React from 'react'
import { Grid } from 'semantic-ui-react';
import { Progress, Label, Transition } from 'semantic-ui-react'
import './asset.css'
import _ from 'lodash'
import axios from 'axios'
import { BlueMoon, RequestToken } from '../BlueMoon'

class AssetGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = { params: this.props.params, topAssets: [], assetInfo: { assetName: undefined, assetValue: undefined, totalValue: 0 } };
    }


    getSum = (topAssets) => {
        return topAssets.reduce((prev, curr) => {
            return prev + _.toNumber(curr.value);
        }, 0);
    }
    

    fetchTopAssets = () => {
        axios.get(`${BlueMoon.ip}dashboard/assetContrib/`, {
            params: this.state.params
        }).then((response) => {
            this.setState({
                topAssets: response.data.assetContrib, totalValue: this.getSum(response.data.assetContrib),
                assetInfo: {
                    assetName: undefined,
                    assetValue: undefined,
                    totalValue: this.state.totalValue
                }
            });
        }).catch((err)=>{
            RequestToken();
        });
    }

    componentDidMount = () => {
        this.fetchTopAssets();
    }

    componentWillReceiveProps = (nextProps, currentProps) => {
        if (!_.isEqual(nextProps.params, this.state.params)) {
            this.setState({ params: nextProps.params, topAssets: [] }, () => {
                this.fetchTopAssets();
            });
            return true;
        }
        return false;
    }

    componentDidUpdate = () => {
        var bars = document.getElementsByClassName('bar');
        for (var index = 0; index < bars.length; index++) {
            bars[index].style.borderRadius = '0px'
            bars[index].style.backgroundColor = 'violet'
        }

    }

    getAssetGrid = (assetName, percentAchieved) => {
        return (<Grid>
            <Grid.Row>
                <Grid.Column width={6} verticalAlign={'middle'} style={{ padding: '1px' }}>
                    <div>
                        <div style={{ marginLeft: '0.3rem', fontSize: window.innerWidth/2/2/3/130+"em" }}><span style={{ fontFamily: 'sans-serif', fontWeight: '900', color: 'wheat', width: '100%' }}>
                            {assetName}
                        </span></div>
                    </div>
                </Grid.Column>
                <Grid.Column width={7} verticalAlign={'middle'} style={{ padding: '0' }}>
                    <Progress className={"progress rounded"} style={{ width: '93%', marginTop: '1rem', marginLeft: '0.3rem', borderRadius: '4px', backgroundColor: "hsl(199 12% 30%)", margin: "0"}} percent={_.round(percentAchieved, 2) * 100} size={'tiny'} color={'violet'} indicating />
                </Grid.Column>
                <Grid.Column width={2} textAlign={'left'} verticalAlign={'middle'} style={{ margin: '0', padding: "0", display: "flex" }}>
                    <Label style={{fontSize: "calc(100vw/100*0.8)"}} color={'teal'}>{_.round(_.round(percentAchieved, 2) * 100)}%</Label>
                </Grid.Column>
            </Grid.Row>
        </Grid>)
    }

    setAssetInfo = (assetDetails) => {
        let temp = this.state.assetInfo;
        temp['assetName'] = assetDetails.assetName;
        temp['assetValue'] = assetDetails.value;
        this.setState({ assetInfo: temp })
    }

    buildAssetPortfolio = () => {
        var topAssets = this.state.topAssets;
        if (_.isEmpty(topAssets)) return null;
        return (
            <Grid style={{ backgroundColor: '#161A1C', marginTop: '0.3rem', boxShadow: "hsl(200 12% 30%) 0 0 8px"}}>

                {_.map(topAssets, (asset, index) => {
                    return (
                        <Grid.Row className="asset" onClick={this.setAssetInfo.bind(this, asset)}>
                            <Grid.Column>
                            <Transition   transitionOnMount = {true}  duration={1000*index }>
                                {this.getAssetGrid(asset.assetName, asset.percentile)}
                                </Transition>
                            </Grid.Column>
                        </Grid.Row>
                    )
                })
                }


            </Grid>

        )
    }

    displayAssetText = (params) => {
        if (!_.isUndefined(params)) {
            var params = params.split("<<!!>>")
            if (params.length > 1)
                return `Top ${params.length} Assets`
            else
                return _.capitalize(params[0])

        }

        return "Top Assets"

    }

    render() {
        if (_.isEmpty(this.state.topAssets)) return null;

        return (
            <div style={{  marginRight: '1rem' }}>
                <Label as='a' ribbon color={'blue'} size={'large'}>{this.displayAssetText(this.state.params.location)}</Label>
                {this.buildAssetPortfolio()}
            </div>
        )
    }

}

export default AssetGrid;