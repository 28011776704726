import React from 'react'
import axios from 'axios'
import _ from 'lodash'
import { Table, Icon, Dropdown, Input, Dimmer, Loader, Image, Modal, Button } from 'semantic-ui-react'
import { BlueMoon, Dimple, RequestToken } from '../BlueMoon';
import './dbData.css'
import { setTimer } from '../UserBehaviour/UserBehaviourTimer'
import ErrorMessage from '../Common/ErrorMessage';
import { Helmet } from 'react-helmet'
import { numberWithCommas } from '../Common/NumberWithComas';
import RowComponent from './RowComponent'
import Status from './Status'

class DBData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            deletedRows: [],
            brandToAssetMapping: [],
            editData: {},
            sort: { meScore: true, eventDate: false, durationTime: false },
            sortPriority: ["meScore", "durationTime", "eventDate"],
            brands: [], assets: [], params: {},
            dimmerActive: false, tableData: [], maxOffset: 0, offset: 0, colors: ["#161A1C", "#4C464F", "#4F7E7A", "#AD3498", "#AD345B", "#B46C1D", "#B1B41D", "#7E4F77", "#7E4F60", "#7E564F", "#637E4F", "#4F727E", "#564F7E"]
            , filter: {}, error: false
        };
    }



    shouldComponentUpdate(nextProps, nextState) {

        if (!_.isEqual(this.state.tableData, nextState.tableData))
            return true;

        else if (this.state.deletedRows.length > 0 && nextState.deletedRows.length == 0)
            return true;

        if (nextState.editData.length > 0) return true;

        if (this.state.edit != nextState.edit) return true

        return !_.isEqual(this.state.sort, nextState.sort)
    }

    componentWillReceiveProps = (nextProps, currentProps) => {
       console.log(this.state.params, nextProps.params);
        setTimer('rawdata');
        if (!_.isEqual(this.state.params, nextProps.params))
            this.fetchData();
    }

    hitUpdateRequest = (data, index) => {
        axios({
            method: 'post',
            url: `${BlueMoon.ip}editData/change/${localStorage.getItem('user')}`,
            data: data
        }).then((response) => {
            if (response.data) {
                var editData = this.state.editData;
                editData[index].status = true;
                this.setState({ editData: editData })
            }
        });
    }

    updateToDB = () => {

        this.state.editData.map((row, index) => {
            if (!row.status) {
                this.hitUpdateRequest({ [row.operation]: row.data }, index);
            }
        });

    }


    addChanges = () => {
        var dataLength = this.state.backupData.length;
        var changedRows = _.range(0, dataLength).filter((row, index) => {
            return (!_.isEqual(this.state.backupData[index], this.state.tableData[index]) && !this.state.deletedRows.includes(this.state.tableData[index]._id) && index < dataLength)
        }).map((index) => {
            return [this.state.tableData[index], this.state.backupData[index]]
        });

        var deletedRows = this.state.deletedRows.map((id) => {
            return { data: this.state.backupData.filter((x) => x._id === id), status: false, operation: "delete" }
        });

        var editRows = changedRows.map((x) => {
            var oldChange = x[1]
            var newChange = x[0]

            return { data: [oldChange, newChange], status: false, operation: "edit" };
        })


        this.setState({ editData: _.concat(editRows, deletedRows) }, () => {
            this.updateToDB();
        })
    }

    fetchDataCount = () => {
        axios.get(`${BlueMoon.ip}dashboard/dataCount/`, {
            params: this.props.params
        }).then((response) => {

            this.setState({ maxOffset: _.floor(response.data.dataCount[0].totalDataCount / 50), totalDataCount: response.data.dataCount[0].totalDataCount, offset: 0 }, () => {
                this.fetchRawData();
            });
        }).catch((err) => {
            RequestToken();
        });
    }

    deleteRow = (id) => {
        var deletedRows = this.state.deletedRows;
        deletedRows.push(id);
        this.setState({ deletedRows: deletedRows })
    }

    fetchRawData = () => {
        axios.get(`${BlueMoon.ip}DbData`, {
            params: Object.assign(this.props.params, {
                offset: this.state.offset, sort: _.keys(this.state.sort).reduce((prev, curr) => {
                    if (prev != "")
                        return prev + ` ${curr}=${this.state.sort[curr]}`

                    return prev + `${curr}=${this.state.sort[curr]}`
                }, ''), sortPriority: this.state.sortPriority.join(",")
            })
        }).then((response) => {
            if (!_.isEmpty(response.data)) {
                this.setState({ tableData: response.data, backupData: _.cloneDeep(response.data), finalBackup: _.cloneDeep(response.data), dimmerActive: false, error: false, sort: this.state.sort });
            }
            else
                this.setState({ error: true })
        });
    }

    fetchData = () => {
        this.setState({tableData: [], dbDate: [], backupData: [], dimmerActive: true }, () => {
            this.fetchDataCount();
        })
    }


    getUniqueBrandsToAssets = () => {
        axios.get(`${BlueMoon.ip}distinct/brand/asset/`, {
        }).then((response) => {
            this.setState({
                brandToAssetMapping: response.data,
                brands: _.keys(response.data).map((x) => { return { text: x, value: x, key: x } }),
            });

        });
    }


    fetchBrandsAssets = () => {
        this.getUniqueBrandsToAssets();
    }

    componentDidMount = () => {
        this.fetchBrandsAssets();
        this.fetchData();
    }



    onSort = (column) => {
        var sortPriorityTemp = this.state.sortPriority;

        if (this.state.sortPriority.includes(column))
            sortPriorityTemp.splice(sortPriorityTemp.indexOf(column), 1);
        sortPriorityTemp.unshift(column);

        var tempSort = this.state.sort;
        if (column in tempSort)
            tempSort[column] = !tempSort[column]
        else
            tempSort[column] = false

        this.setState({ sort: tempSort, sortPriority: sortPriorityTemp, tableData: [], offset: 0}, () => {
            this.fetchRawData()
        })
    }

    refresh = () => {
        console.log("refreshed")
        window.location.reload();
    }

    componentDidUpdate = () => {
        console.log(this.state.edit)
        if (!_.isEmpty(document.getElementsByTagName('tr'))) {
            var dropDowns2 = document.getElementsByClassName("ui selection dropdown")

            var thElements = document.getElementsByTagName('tr')[0].getElementsByTagName('th');
            for (var i = 0; i < thElements.length - 1; i++) {
                if (i > 0) {
                    thElements[i].setAttribute('style', 'font-size: 1.1em;color: chartreuse !important;font-family: sans-serif;paddingLeft:0em;paddingRight:0em;');
                    thElements[i].style.zIndex = 0;
                    thElements[i].style.zIndex = 0;
                }
            }

            if (dropDowns2.length > 0) {
                for (var i = 0; i < dropDowns2.length / 2; i++) {
                    dropDowns2[2 * i].style.backgroundColor = "#333";
                    dropDowns2[2 * i].style.color = 'chartreuse';
                    dropDowns2[2 * i + 1].style.backgroundColor = "#333";
                    dropDowns2[2 * i + 1].style.color = 'chartreuse';
                }
            }
        }

    }

    renamedHeaders = (header) => {
        switch (header) {
            case "sourceMediaType": return "type"; break;
            case "eventDate": return "Event date"; break;
            case "channelName": return "channel"; break;
            case "chtype": return "channelType"; break;
            case "durationTime": return "duration"; break;
            default: return header;
        }
    }

    aliasToDBName = (header) => {
        switch (header) {
            case "type": return "sourceMediaType"; break;
            case "channel": return "channelName"; break;
            case "channelType": return "channelType"; break;
            case "eventDate": return "Event Date"; break;
            case "Brand": return "brand"
            default: return header;
        }
    }


    reset = () => {
        this.setState({ tableData: [], backupData:[], deletedRows: [], sort: { meScore: true, eventDate: false, durationTime: false },
            sortPriority: ["meScore", "durationTime", "eventDate"]}, () => {
            this.fetchRawData();
        });
    }

    addRowBack = (id) => {
        var deletedRows = this.state.deletedRows;
        var index = deletedRows.indexOf(id);
        deletedRows.splice(index, 1);
        this.setState({ deletedRows: deletedRows });
    }

    render() {
        if (_.isEmpty(localStorage.getItem('token'))) window.location.replace(`${Dimple}#/login`);
        
        if (_.isEmpty(localStorage.getItem('projectIp'))) window.location.replace(`${Dimple}#/home`);

        if(localStorage.getItem('userRole') !== "admin")  return <ErrorMessage isNormal = {true}/>

        if (this.state.error) return <ErrorMessage />

        if (this.state.dimmerActive || _.isEmpty(this.state.brands))
            return <Dimmer active={true}>
                <Loader />
            </Dimmer>
        if (this.state.editData.length > 0) return <Status editables={this.state.editData} refresh={this.refresh} />
        return (<div>


            <div className={'tableFixHead'} style={{ marginTop: '1rem' }}>
                <table style={{ backgroundColor: '#161A1C', color: 'white' }} >
                    <thead>
                        <tr>
                            <th> <Icon name={(this.state.edit) ? 'cancel' : 'pencil'} style={{ cursor: 'pointer' }} onClick={() => { this.setState({ edit: !this.state.edit }) }} /> </th>
                            {
                                _.keys(this.state.tableData[0]).map((header, index) => {

                                    if (_.isEqual(header, "meScore") || _.isEqual(header, "durationTime") || _.isEqual(header, "eventDate")) {
                                        var isDesc = (this.state.sort[(header == "duration") ? "durationTime" : header])
                                        isDesc = (_.isUndefined(isDesc)) ? false : isDesc
                                        return <th>{(header == "meScore") ? "ROI($)" : _.capitalize(header)}<Icon onClick={this.onSort.bind(this, (header == "duration") ? "durationTime" : header)} style={{ cursor: 'pointer' }} disabled={this.state.edit} name={(!isDesc) ? 'caret down' : 'caret up'} /></th>
                                    }
                                    header = this.renamedHeaders(header);

                                    if (!(_.isEqual(header, "project") | _.isEqual(header, "mediaUrl") | _.isEqual(header, "_id")))
                                        return <th style={{ fontFamily: 'sans-serif', fontSize: '1.1em', padding: '0em' }} textAlign={'center'} verticalAlign={'middle'} >{_.capitalize(this.renamedHeaders(header))}</th>
                                })

                            }
                            <th><Icon name={'image'} size={'large'} /> </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.tableData.map((row, index) => {
                                var backgroundColor = this.state.colors[(index + 1) % 2];
                                var isDeleted = this.state.deletedRows.includes(row._id)

                                return <RowComponent edit={this.state.edit} brandToAssetMapping={this.state.brandToAssetMapping} isDeleted={isDeleted} brandNames={this.state.brands} deleteRow={this.deleteRow} addRow={this.addRowBack} editRow={this.editRow} row={row} index={index} backgroundColor={backgroundColor} />
                            })
                        }

                    </tbody>
                </table>
            </div>
            <div style={{ flex: 'wrap', float: 'left', marginTop: '1rem' }}>
                <Button color={'green'} content={'Save'} onClick={this.addChanges} />
            </div>
            <div style={{ flex: 'wrap', float: 'right', marginTop: '1rem' }}>

                <Button color={'blue'} onClick={this.reset} style={{ marginRight: '1rem' }}> {'Reset'}</Button>
                <span style={{ color: 'white', marginRight: '1rem' }}>{`${this.state.offset + 1}-${numberWithCommas(_.toString(this.state.maxOffset + 1))}   of ${numberWithCommas(_.toString(this.state.totalDataCount))}`}</span>
                <Icon color={'grey'} name={'caret left'} style={{ cursor: 'pointer' }} disabled={(this.state.offset > 0) ? false : true} size={'big'} onClick={() => {
                    this.setState({ offset: this.state.offset - 1, tableData: [], backupData: [], dimmerActive: true }, () => {
                        this.fetchRawData();
                    })
                }} />
                <Icon name={'caret right'} color={'grey'} style={{ cursor: 'pointer' }} disabled={(this.state.offset == this.state.maxOffset) ? true : false} size={'big'} onClick={() => {
                    this.setState({ offset: this.state.offset + 1, tableData: [], backupData: [], dimmerActive: true }, () => {
                        this.fetchRawData();
                    })
                }} />
            </div>
            <Helmet>

                <style type="text/css">{`
       .ui.labeled.icon.button, .ui.labeled.icon.buttons .button{
           padding-left: 1.5em !important;
           padding-right: 0.5em !important;
       }
    `}</style>
            </Helmet>
        </div>

        )

    }
}

export default DBData;