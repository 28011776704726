
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import React from "react"
import _ from "lodash"
import './tooltip.css'

import { withRouter } from "react-router-dom";
import { setTimer, updateTimings } from "../UserBehaviour/UserBehaviourTimer";

class NavBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = { menuBarData: [{ fontIconClass: "fa fa-fw fa-home", fontSize: '1.75em', color: "#1e90ff", text: "Home" }, { fontIconClass: "fa fa-fw fa-line-chart", fontSize: '1.75em', color: "#1e90ff", text: "Analytics" }, { fontIconClass: "fa fa-file", fontSize: '1.75em', color: "#1e90ff", text: "Report" }, { fontIconClass: "fa fa-lightbulb-o", fontSize: '1.75em', color: 'yellow', text: 'Insights' }], defMenuSelected: "home",
                        expanded: false };
    }


    navBarOnClick = (routerPush, selected) => {
        updateTimings();
        this.setState({
            defaultSelected: selected
        }, () => {
            if(!_.isEqual(selected, "user-logs"))
                setTimer(_.replace(routerPush, '/', ''));
            this.props.history.push(`${routerPush}`);
        });
    }

    componentDidMount = () => {
        window.addEventListener("resize", function(){console.log("window resizing");});

    }

    render() {
        if (this.state != {})
            return (
                <SideNav
                onToggle={(expanded) => {
                    this.setState({expanded: expanded});
                    console.log("Exp", this)

                }}
                className={this.state.expanded ? "expanded-nav": "collapsed-nav"}
                    style={{ backgroundColor: 'black', marginTop: '4rem', position: 'fixed' }}
                    onSelect={(selected) => {
                        // Add your code here
                        if (selected === "bulb"){
                            this.navBarOnClick('/insights', selected);
                            
                        }
                        else if (selected === "home"){
                            this.navBarOnClick('/dashboard/', selected)
                        }
                        else if (selected === "analytics"){
                            this.navBarOnClick('/analytics', selected)
                        }
                        else if (selected === "report"){
                            this.navBarOnClick('/report', selected)
                        }
                        else if (selected === "user-logs"){
                        this.navBarOnClick('/logs', selected)
                        }
                        this.setState({ defaultSelected: selected })
                    }}
                >
                    <SideNav.Toggle />
                    <SideNav.Nav defaultSelected={this.state.defMenuSelected} style = {{display: 'grid'}}>
                        <NavItem eventKey="home" className = {'tooltip'}> 
                            <NavIcon >
                                <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em', color: "#0097FA"}} />
                            </NavIcon>
                            <NavText>
                                Home
                        </NavText>
                        <span class="tooltiptext">Dashboard</span>
                        </NavItem>
                        <NavItem eventKey="analytics" className = {'tooltip'}>
                            <NavIcon>
                                <i className="fa fa-fw fa-line-chart" style={{ fontSize: '1.75em', color: "#0097FA" }} />
                            </NavIcon>
                            <NavText>
                                Data Analytics
                        </NavText>
                        <span class="tooltiptext">Analytics</span>

                        </NavItem>
                        <NavItem eventKey="report" className = {'tooltip'}>
                            <NavIcon>
                                <i className="fa fa-file" style={{ fontSize: '1.75em', color: "#0097FA" }} />
                            </NavIcon>
                            <NavText>
                                Report
                        </NavText>
                        <span class="tooltiptext">Report</span>
                        </NavItem>
                        <NavItem eventKey="bulb" className = {'tooltip'}>
                            <NavIcon>
                                <i className="fa fa-lightbulb-o" style={{ fontSize: '1.75em', color: "yellow" }} />
                            </NavIcon>
                            <NavText>
                                Insights
                        </NavText>
                        <span class="tooltiptext">Insights</span>
                        </NavItem >
                        {(_.isEqual(localStorage.getItem('permissions'), 'super-user') )?<NavItem eventKey="user-logs" className = {'tooltip'}>
                            <NavIcon>
                                <i className="fa fa-users" style={{ fontSize: '1.75em', color: "#0097FA" }} />
                            </NavIcon>
                            <NavText>
                                Users
                        </NavText>
                        <span class="tooltiptext">Tracker</span>
                        </NavItem>: null}
                    </SideNav.Nav>
                </SideNav>
            )
        return null;
    }
}

export default withRouter(NavBar);