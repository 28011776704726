import React from 'react'
import { Grid, Image, Label, Transition } from 'semantic-ui-react'
import _ from 'lodash'
import axios from 'axios'
import { BlueMoon, RequestToken } from '../BlueMoon'
import {numberWithCommas} from '../Common/NumberWithComas'
import { isMobile } from 'react-device-detect'

class BrandGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            topBrands: [], params: this.props.params
        };
    }

    componentWillReceiveProps = (nextProps, currentProps) => {
        if (!_.isEqual(nextProps.params, this.state.params)) {
            this.setState({ topBrands: [], params: nextProps.params }, () => {
                this.fetchTopBrands();
            });
            return true;
        } return false;

    }

   

    fetchTopBrands = () => {
        axios.get(`${BlueMoon.ip}dashboard/topbrands/`, {
            params: this.state.params
        }).then((response) => {
            this.setState({ topBrands: response.data.brandContrib })
        }).catch((err)=>{
            RequestToken();
        });;
    }

    componentDidMount = () => {
        this.fetchTopBrands();
    }


    imageGrid = (brandName, avgValue, totalValue, rating, imageSrc) => {
        console.log(avgValue);
        var width = 0;
        var padding = 0;
        if (window.innerWidth < 1250) {
            width = "60%"
            padding = "0.2rem"
        }
        else if(isMobile){

        }
        else {
            width = "90%"
            padding = "0.4rem"
        }
        return (
            
            <Grid style={{ backgroundColor: 'hsl(200 12% 17%)', width: '100%', height: '100%', borderRadius: "12px", margin: "0"}}>
                <Grid.Row style={{borderRadius: "12px",height: "100%" , boxShadow: "hsl(200 12% 30%) 0 0 4px"}}>
                    <Grid.Column width={7} style={{ padding: "12px"}}>
                        <Image style={{borderRadius: "4px", boxShadow: "#020202 9px 10px 20px 2px"}} src={imageSrc} size = {'small'}/>
                    </Grid.Column>
                    <Grid.Column width={9} textAlign={'left'} style={{display: "flex", alignItems: "center"}} >
                        <Grid style={{margin: 0, display: "flex", flexDirection: "column",  gap: "12px"}}>
                            <Grid.Row style={{ padding: "0" }}>
                                <Grid.Column width={16} textAlign={'left'}><Label color={'orange'} size={'medium'} style = {{fontSize: (isMobile)?"0.6em":(window.innerWidth-window.innerWidth*0.20 )/3/2/2/100 + "em"}}>Avg: ${numberWithCommas(avgValue)}</Label></Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{ padding: "0", paddingRight: '0rem' }}>
                                <Grid.Column width={16} style={{ color: 'white', fontFamily: 'sans-serif', fontWeight: '900', fontSize: (isMobile)?"0.4em":(window.innerWidth-window.innerWidth*0.20 )/3/2/2/100 + "em", width: '100%' }} textAlign={'left'}> {brandName}</Grid.Column>
                            </Grid.Row>
                            {/* <Grid.Row style = {{padding: "0.22rem"}}>
                                <Grid.Column width={16} textAlign = {'left'}><Rating icon='star' defaultRating={rating} maxRating={5}  /></Grid.Column>
                            </Grid.Row> */}
                            <Grid.Row style={{padding: "0"}}>
                                <Grid.Column width={16} textAlign={'left'}><Label size={'medium'} color={'violet'} style={{ margin: "0", fontSize: (isMobile)? "0.6em": (window.innerWidth-window.innerWidth*0.20 )/3/2/2/100 + "em"
 }} >Total: ${numberWithCommas(totalValue)} </Label></Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }



    createBrandGrid = () => {
        if (!_.isEmpty(this.state.topBrands)) {
            var topBrands = this.state.topBrands;
            var totalColumns = 3;
            var numberOfRows = _.ceil(topBrands.length / totalColumns)
            return (<Grid style={{ marginTop: '0.5rem', marginLeft: '0.1rem'}} stackable>
                {
                    _.map(_.range(0, numberOfRows), (row) => {
                        
                        return (
                            
                            <Grid.Row columns={3} style={{ paddingLeft: 0, paddingTop: (row == 0)?'inherit': 'none' }}>
                                {
                                    _.map(_.range(row * totalColumns, row * totalColumns + 3), (columnNumber) => {
                                        if (columnNumber >= topBrands.length)
                                            return null;
                                        return (
                                            <Grid.Column style={{ paddingLeft: 0, marginTop: '0.5rem' }}>
                                                 <Transition   transitionOnMount = {true}  duration={1000*(columnNumber+1.5) }>
                                                {this.imageGrid(topBrands[columnNumber]['Brand'], _.round(topBrands[columnNumber]['avgFrequency']), _.round(topBrands[columnNumber]['totalValue']), 5 - columnNumber, topBrands[columnNumber]['imgSrc'])}
                                                </Transition>
                                            </Grid.Column>
                                        )
                                    })
                                }
                            </Grid.Row>
                        )
                    })
                }
            </Grid>)
        }
        return null;
    }

    displayBrandLabelText = (params) => {
        if(!_.isUndefined(params)){
            var params = params.split("<<!!>>")
            if(params.length > 1)
            return `Top ${params.length} Brands`
            else
            return _.capitalize(params[0])
            
        }

        return "Top Brands"
       
    }

    render() {
        if (_.isEmpty(this.state.topBrands)) return null;
        return <div style={{ "padding": "15px 16px 29px 16px",  width: "99%", borderRadius: "12px", backgroundColor: "hsl(200 12% 10%)"}}>
            
            <Label as='a' ribbon color={'blue'} size={'large'}>{this.displayBrandLabelText(this.state.params.Brand)}</Label>
            
            {this.createBrandGrid()}
        </div>
    }
}

export default BrandGrid;