import React from 'react'
import {Tab} from 'semantic-ui-react'
import UserLogs from './UserLogs'
import {Dimple} from '../BlueMoon'
import UserTracker from './UserTracker'
import _ from 'lodash'

class UserBehaviour extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }

    componentDidMount = () => {
        this.setState({set: true});
    }

    componentDidUpdate = () => {
    var updateElements = document.getElementsByClassName("ui attached tabular menu")[0];
    if (!_.isUndefined(updateElements)) {
        updateElements.style.border = '1px';
        updateElements = updateElements.getElementsByTagName("a");

        for (var i = 0; i < updateElements.length; i++) {
            if (i == 0)
                updateElements[i].style.backgroundColor = 'violet';
            else if (i == 1)
                updateElements[i].style.backgroundColor = 'teal';
            else
                updateElements[i].style.backgroundColor = '#AF1B96';
            
            updateElements[i].style.border = 'none';
            updateElements[i].style.color = 'black';
            updateElements[i].style.fontFamily = 'sans-serif';
            updateElements[i].style.fontWeight = 'bolder';
        }

    }
}

    render(){
        const panes = [
            { menuItem: 'Logs', render: () => <Tab.Pane style={{ backgroundColor: '#333', border: '1px solid black' }}><UserLogs/></Tab.Pane> },
            { menuItem: 'Behaviour', render: () => <Tab.Pane style={{ backgroundColor: '#333', border: '1px solid black' }}><UserTracker/></Tab.Pane> },
            
          ]
          if(_.isEmpty(localStorage.getItem('user'))) window.location.replace(`${Dimple}#/login`);

          return <Tab style = {{marginLeft: '5rem', marginTop: '5rem', marginRight: '1rem'}} panes = {panes}/>
    }
}

export default UserBehaviour;