import React from 'react'
import {Button, Header} from 'semantic-ui-react'
import GroupComparison from './GroupComparison'
import DisplayCollection from './DisplayCollection'
import { isMobile } from 'react-device-detect';
import _ from 'lodash'
import { Dimple } from '../BlueMoon'

class Report extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            display: "reportComparison"
        };
    }

    createButtonTabs = () => {
        return <div style = {{marginTop: '5rem', marginLeft: (isMobile)?"4rem":'5%'}}>
            <Button color = {'orange'} content = {'Display'} onClick = {() => {
                this.setState({display: "displayReport"})
            }}/>
            <Button color = {'olive'} content = {'Comparison'} onClick = {
            () => {
                this.setState({display: "reportComparison"})
            }
            }/>
        </div>
    }

    render(){
        if(_.isEmpty(localStorage.getItem('user'))) this.props.history.push('./login')
        if (_.isEmpty(localStorage.getItem('projectIp'))) window.location.replace(`${Dimple}#/login`)

        return <div>
             <Header as='h3' style = {{backgroundColor: '#333', color: 'white', padding: 6, fontSize: '1.5em', marginLeft: (isMobile)?'4rem':'5%', marginTop: '5rem'}}>
    Collection Audit
  </Header>
        {this.createButtonTabs()}
        {(this.state.display === "reportComparison")?<GroupComparison/>:<DisplayCollection/>}
        </div>
    }
}

export default Report;