import React from 'react';
import FilterEventDashBoard from './FilterEventDashBoard';
import CreateMediaGrid from './CreateMediaGrid'
import _ from 'lodash'
import axios from 'axios'
import $ from 'jquery'
import { BlueMoon, Dimple, RequestToken } from '../BlueMoon'
import { Header } from 'semantic-ui-react'
import { isMobile } from 'react-device-detect';

class EventDashBoardFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = { totalValue: 0, selectedMedia: {}, filters: [], filterSelect: {}, mediaData: [], events: [], start: 0, stopScrolling: true, sourceIdToName: { 'Facebook': 1, 'Twitter': 2, 'Instagram': 3 }, seperator: "<<!!>>", setItBool: true, filters: [], fil: '', selectAllMedia: false, showErrorMessage: false }
    }

    getDefaultState = () => {
        return { totalValue: 0, selectedMedia: {}, filters: [], filterSelect: {}, mediaData: [], events: [], start: 0, stopScrolling: true, sourceIdToName: { 'Facebook': 1, 'Twitter': 2, 'Instagram': 3 }, seperator: "<<!!>>", setItBool: true, filters: [], fil: '', selectAllMedia: false, showErrorMessage: false }
    }

    fetchTotalValue = () => {
        axios.get(`${BlueMoon.ip}QCData/total/filter?${this.state.fil}`).then((response) => {
            this.setState({

                totalValue: response.data[0].value
            });
        }).catch((err) => {
            RequestToken();
        });
    }

    getFilter = () => { return ({ project: this.state.events }) };

    fillFilters = () => {
        this.setState({ filters: this.getFilter() })
    }

    handleScroll = e => {
        var _ = this;
        var height = $(document).height;
        var isActive = false;

        $(function () {


            $(window).on("scroll", function () {
                if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
                    if (!_.state.stopScrolling) {
                        _.setState({ stopScrolling: true, start: _.state.start + 1 }, () => _.fetchGridParams())
                    }
                }
            });
        });
    }

    loadBottom = () => {
        this.setState({ start: this.state.start + 1 }, () => {
            this.fetchGridParams();
        })
    }

    componentDidMount = () => {

        window.addEventListener('scroll', this.handleScroll);
        axios.get(`${BlueMoon.ip}QCData/projects/list/${this.state.clientName}`).then((response) => {
            if (!_.isNull(response.data.result)) {
                this.setState({ events: response.data[0] }, () => this.fillFilters())
            }
        }).catch((err) => {
            RequestToken();
        })

    }


    mediaFetch = (filterSelect) => {
        axios.get(`${BlueMoon.ip}QCData/filter/?${this.state.fil}&start=${this.state.start * 12}&end=${this.state.start * 12 + 12}`).then((response) => {

            if (!_.isEmpty(response.data)) {
                if (!_.isEmpty(this.state.mediaData)) {
                    let tempMediaData = this.state.mediaData;
                    let tempMediaUrls = this.state.mediaData.mediaUrls;
                    tempMediaData['mediaUrls'] = tempMediaUrls.concat(response.data)
                    this.setState({
                        showErrorMessage: false,
                        mediaData: tempMediaData
                    }, () => {
                        this.setState({ stopScrolling: false })
                    })
                } else {
                    this.setState({
                        showErrorMessage: false,

                        mediaData: {
                            'sourceIdToName': { 1: 'facebook', 2: 'twitter', 3: 'instagram' },
                            'mediaUrls': response.data,
                        }
                    }, () => {
                        this.setState({ stopScrolling: false }, () => {
                            if (!_.isUndefined(filterSelect)) {
                                // this.fetchFilters();
                            }
                        })
                    })
                }
                this.fetchTotalValue();
            } else {
                this.setState({ showErrorMessage: true })
            }
        }).catch((err) => {
            RequestToken();
        });
    }

    fetchFilters = () => {
        axios.get(`${BlueMoon.ip}QCData/filterdata?${this.state.fil}`).then((response) => {
            var temp = Object.assign(this.getFilter(), response.data);
            this.setState({ filters: temp, setItBool: false, selectedMedia: {}, selectAllMedia: false }, () => {
                // console.log(this.state.filters, "printing filters");

                this.mediaFetch();
            })
        }).catch((err) => {
            RequestToken();
        });
    }

    fetchGridParams = (filterSelect) => {
        if (!_.isEmpty(this.state.filters)) {
            if (this.state.start == 0)
                this.fetchFilters();
            else
                this.mediaFetch();
        }
    }

    shouldComponentUpdate = (prevProps, prevState) => {
        return true;
    }

    filterFormat = (value, events) => {
        return ([value] + "=" + this.state.filterSelect[value].map((key) => {
            return events[key]
        }).join(this.state.seperator));
    }

    filterFormatter = (value) => {
        switch (value) {
            case 'brand': return ([value] + "=" + encodeURIComponent(this.state.filterSelect[value].join(this.state.seperator)));
            case 'platform': return ([value] + "=" + encodeURIComponent(this.state.filterSelect[value].map((key) => {
                return _.toLower(key)
            }).join(this.state.seperator)));
            default: return ([value] + "=" + encodeURIComponent(this.state.filterSelect[value].map((key) => {
                return key
            }).join(this.state.seperator)));

        }
    }

    selectMedia = (mediaObject) => {
        var objectId = mediaObject.id;
        let temp = this.state.selectedMedia;
        if (objectId in temp)
            delete temp[objectId];
        else
            temp[objectId] = mediaObject;
        this.setState({ selectedMedia: temp }, () => {
        });
    }



    addCollection = () => {
        this.props.history.push({
            pathname: '/collector',
            state: {
                selectedMedia: this.state.selectedMedia,
                filters: this.state.filterSelect,
                selectAllMedia: this.state.selectAllMedia
            }

        })
    }

    reset = () => {
        this.setState(this.getDefaultState(), () => {
            this.componentDidMount();
        });
    }

    filterSelect = (filterName, selectedValue) => {
        this.state.filterSelect[filterName] = selectedValue;
        this.setState({
            fil: (Object.keys(this.state.filterSelect).map((value) => {
                return this.filterFormatter(value);
            }).join("&")), showErrorMessage: false
            , mediaData: [], start: 0
        }, () => {

            this.fetchGridParams(this.state.filters);
        })

    }

    selectAllMedia = () => {
        this.setState({ selectAllMedia: !this.state.selectAllMedia }, () => {
            this.setState({
                selectedMedia: {}
            })
        });
    }

    render() {
        if (_.isEmpty(localStorage.getItem('user'))) window.location.replace(`${Dimple}#/login`);
        if (_.isEmpty(localStorage.getItem('projectIp'))) window.location.replace(`${Dimple}#/login`)

        if (!_.isObject(this.state.filters) && this.state.setItBool)
            return null
        if (_.isEmpty(this.state.filters)) return null;
        const options = [
            { label: 'Thing 1', value: 1 },
            { label: 'Thing 2', value: 2 },

        ];
        return (
            <div style={{ backgroundColor: '#1E1A19' }} style={{ marginTop: '4rem' }} >
                <Header as='h3' style={{ backgroundColor: '#333', color: 'white', padding: 6, fontSize: '1.5em', marginLeft: (isMobile) ? "3rem" : '5%', marginTop: '5rem' }}>
                    Analysis
  </Header>
                <FilterEventDashBoard reset={this.reset} value={this.state.totalValue} allMedia={this.selectAllMedia} filters={this.state.filters} filterSelect={this.filterSelect} isMediaExists={_.isEmpty(this.state.mediaData) ? 0 : this.state.mediaData.mediaUrls.length > 0} selectedFilter={this.state.filterSelect} addCollection={this.addCollection} />

                {(!_.isEmpty(this.state.fil) || !_.isEmpty(this.state.mediaData)) ? <CreateMediaGrid showErrorMessage={this.state.showErrorMessage} filterChosen={this.state.filterSelect} selectAllMedia={this.state.selectAllMedia} mediaData={this.state.mediaData} selectMedia={this.selectMedia} /> : null}
            </div>
        )
    }
}

export default EventDashBoardFilter;