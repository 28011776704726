import React from 'react'
import ReactEcharts from 'echarts-for-react';
import axios from 'axios'
import _ from "lodash"
import { BlueMoon, RequestToken } from '../BlueMoon'
import { Grid, Label, Dropdown, Message, Loader } from 'semantic-ui-react'
import { numericConvertor } from '../Common/NumericConvertor'
import ErrorMessage from '../Common/ErrorMessage';
import { setTimer } from '../UserBehaviour/UserBehaviourTimer'
import { isMobile } from 'react-device-detect'

class StackedBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: this.props.params, timeLineData: [], selectedGraph: 'Bar', timeLineTop: 1, timeLineDataShow: true, load: true, monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ]
        }
    }


    componentDidUpdate = () => {
        var graphSelector = document.getElementsByClassName('ui search selection dropdown');
        if (!_.isUndefined(graphSelector[5])) {
            graphSelector[5].style.backgroundColor = 'violet'
            if (!_.isUndefined(graphSelector[5].getElementsByClassName('default text')[0])) {
                graphSelector[5].getElementsByClassName('default text')[0].style.color = 'black'
                graphSelector[5].getElementsByClassName('default text')[0].style.fontFamily = 'sans-serif';
                graphSelector[5].getElementsByClassName('default text')[0].style.fontWeight = '900';
            }
        }
        if (document.getElementsByClassName('header').length > 0)
            document.getElementsByClassName('header')[0].style.fontFamily = 'sans-serif'

    }

    channelToDataMapper = (prev, curr) => {
        if (curr.channelName in prev) {
            prev[curr.channelName]['frequency'].push(curr.frequency);
        }
        else {
            prev[curr.channelName] = {};
            prev[curr.channelName]['frequency'] = [];
            prev[curr.channelName]['frequency'].push(curr.frequency);
        }
        return prev;
    }


    fetchOpponents = () => {
        console.log("fetching ... ", BlueMoon.ip)
        axios.get(`${BlueMoon.ip}opponents/${_.isUndefined(this.state.params.duration) ? "all" : this.state.params.duration}`).then((response) => {
            this.setState({
                dateToOpponents: response.data
            }, () => {
                console.log(this.state.dateToOpponents)
                this.fetchData();
            })
        }).catch((err) => {
            RequestToken();
        });
    }

    fetchData = () => {
        console.log(this.state.params)
        let params = this.state.params;
        if(_.isEmpty(this.state.params)){
            params = {duration: "week"};
        }
        axios.get(`${BlueMoon.ip}dashboard/meScore/`, {
            params: params
        }).then((response) => {
            this.setState({ timeLineData: response.data.meScore, timeLineDataShow: true, load: false });
        }).catch((err) => {
            RequestToken();
        });
    }

    componentDidMount = () => {
        setTimer('dashboard');
        this.fetchOpponents();
    }


    componentWillReceiveProps = (nextProps, currentProps) => {
        setTimer('dashboard');
        if (!_.isEqual(this.state.params, nextProps.params)) {
            this.setState({
                params: nextProps.params,
                timeLineData: [],
                load: true,
                unvisibleTimer: window.clearTimeout(this.state.unvisibleTimer),
                visibletimer: window.clearTimeout(this.state.visibletimer)
            }, () => {
                this.fetchOpponents();
            });
            return true;
        } else {
            return false;
        }
    }

    normalDateToTimeLine = (date) => {
        var dateSplit = date.split('-');
        if (dateSplit.length > 1) {
            var date = new Date(dateSplit[0], _.toInteger(dateSplit[1]) - 1, dateSplit[2])
            var year = _.toInteger(date.getYear()) + 1900;
            return (date.getDate() + " " + _.capitalize(this.state.monthNames[date.getMonth()]) + " " + _.toString(year).slice(2, 4))
        }
        return date
    }


    getMonthFromString = (mon) => {
        return new Date(Date.parse(mon + " 1, 2012")).getMonth() + 1
    }


    timeLineDateToNormal = (date) => {
        var dateSplitter = date.split(" ");
        var year = dateSplitter[2];
        var day = dateSplitter[0];
        var month = dateSplitter[1];
        var monthNumber = this.getMonthFromString(month);
        return "20" + year + "-" + String(monthNumber).padStart(2, '0') + "-" + String(day).padStart(2, '0')
    }


    stackerBar = (data, duration, timeLineDateToNormal) => {
        // console.log(isMobile)
        var duration = this.state.params.duration;
        var dataLength = data.length;
        console.log(dataLength, ' is the data length !! ')
        const xAxisData = Array.from(new Set(data.map((x) => {
            return this.normalDateToTimeLine(x.date)

        })));
        console.log(xAxisData);
        var channelToData = data.reduce(this.channelToDataMapper, {});
        var dateToOpponents = this.state.dateToOpponents
        console.log(dateToOpponents, ' is the dateToOpponents !! ')
        var option = {};
        option = {
            animationDurationUpdate: function (idx) {
                // delay for later data is larger
                return 3000;
            },
            animationEasing: true,
            backgroundColor: '#161A1C',

            title: {
                text: (isMobile) ? "$" : "ROI($)",
                textStyle: {
                    fontFamily: 'sans-serif',
                    fontWeight: 'bold',
                    interval: 4
                },
                left: '20px',
                top: '5px'
            },
            legend: {
                formatter: function (name) {
                    return name;
                }
            },
            tooltip: {

                textStyle: {
                    fontFamily: 'sans-serif',
                    fontSize: 13
                },
                trigger: 'axis',
                formatter: function (params) {

                    if (duration === "monthname"){
                        var year = _.keys(dateToOpponents)[0].split("-")[1]
                        var opponents = dateToOpponents[params[0].axisValue + "-" + year];
                    }
                    else
                        var opponents = dateToOpponents[timeLineDateToNormal(params[0].axisValue)];
                        
                    
                    return `${params[0].axisValue}<br/> ${(_.isUndefined(opponents)) ? "" : "Opponents:<br/>" + _.split(opponents, ",").join("<br/>")}<br/>` + params.map((data) => {
                        return `${data.data.channelName}:&nbsp$${numericConvertor(data.value)}`
                    }).join("<br/>");
                },
                axisPointer: {
                    type: 'shadow',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },

            toolbox: {
                feature: {
                    saveAsImage: {
                        title: "download image"
                    }
                }
            },

            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                height: '250px',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: true,
                    data: xAxisData,
                    axisLabel: {
                        fontFamily: 'sans-serif',
                        fontWeight: 'bold',
                        interval: (duration === "monthname" || duration === "week") ? "" : (isMobile) ? 15 : _.round(Math.log2(dataLength))
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine: {
                        show: false
                    }
                }
            ],
            series: (this.state.timeLineDataShow) ? _.keys(channelToData).map((channel) => {
                return {
                    type: 'bar',
                    stack: 'stack',

                    barCategoryGap: '30%',
                    name: channel,
                    thickness: '11',
                    data: channelToData[channel].frequency.map((freq) => {
                        return { value: freq, channelName: channel }
                    }),
                    color: this.props.channelToColor[channel],
                }
            }) : []
        };
        return option;
    }

    techToLayManTerms = (text) => {
        return _.capitalize(_.replace(text, '_', ''));
    }



    render() {
        if (_.isEmpty(this.state.timeLineData)) return (
            <div>
                <Loader active={this.state.load} />
                {(!this.state.load) ? <ErrorMessage /> : null}
            </div>)

        return (
            <div >

                {
                    <ReactEcharts
                        style={{boxShadow: "hsl(200 12% 30%) 0 0 8px"}}
                        option={this.stackerBar(this.state.timeLineData, this.state.params.duration, this.timeLineDateToNormal)}
                        notMerge={true}
                        lazyUpdate={true}
                        theme={"dark"}
                    />
                }
            </div>
        )
    }


}

export default StackedBar;